<template>
    <b-overlay :show="showLoader">
        <b-card>
            <h3 style="font-weight: bold;">Izpolnite podatke o oglasu</h3>
            <validation-observer ref="validate">
                <b-form class="form">
                    <advertisement ref="advertisement" v-model="object" type="edit"/>
                    <b-row>
                        <b-col class="text-center text-md-right">
                            <b-button class="mt-1 mt-sm-0" variant="warning" @click="$router.replace({name: 'admin-advertisement-list'})">PREKLIČI</b-button>
                            <b-button class="mt-1 mt-sm-0 mx-1 mr-sm-0" variant="secondary" @click.prevent="validationForm">UREDI OGLAS</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import Advertisement from '../../Components/Advertisement'
    import {BButton, BCard, BCol, BForm, BOverlay, BRow} from 'bootstrap-vue'
    export default {
        components: {
            ValidationObserver,
            Advertisement,
            BOverlay,
            BCard,
            BForm,
            BButton,
            BRow,
            BCol
        },
        data() {
            return {
                object: {
                    main_photo:null,
                    device_type:[],
                    page_type:[],
                    show_from: '',
                    show_until: '',
                    url:'',
                    active: true
                },
                showLoader: false,
                required
            }
        },
        methods: {
            getAdd() {
                const thisIns = this
                thisIns.dataLoaded = true
                const loadPromise = this.$http.get(`/api/management/v1/advertisement/${thisIns.$route.params.advertisement_id}`)
                loadPromise.then(function(response) {
                    thisIns.object = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            async validationForm() {
                this.$refs.validate.validate().then(async(success) => {
                    if (success) {
                        await this.editAdd()
                    }
                })
            },
            async editAdd() {
                this.showLoader = true
                try {
                    const isImageUploadSuccessfull = await this.$refs.advertisement.uploadImageToAPI()
                    if (!isImageUploadSuccessfull) {
                        return
                    }

                    await this.$http.patch(`/api/management/v1/advertisement/${this.$route.params.advertisement_id}`, this.object)
                    this.$printSuccess('Oglas je urejen')
                    this.showLoader = false
                    setTimeout(() => {
                        this.$router.push({name: 'admin-advertisement-list'})
                    }, 1000)

                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            }
        },
        created() {
            this.getAdd()
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>